import { PropsWithChildren, useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Hidden,
  Typography,
  useTheme
} from '@mui/material';
import { Link } from 'common/components/Link';
import { useOnScreen } from 'common/hooks/useOnScreen';

export interface HeroProps {
  headline: string;
  description?: string;
  button?: ButtonProps;
  backgroundImage?: string;
}

const Hero = (props: PropsWithChildren<HeroProps>) => {
  const theme = useTheme();
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const isOnscreen = useOnScreen(ref, 300);
  const { backgroundImage, headline, description, button } = props;

  return (
    <Box
      ref={setRef}
      sx={{
        ...(isOnscreen ? theme.mixins.makeBackground(backgroundImage) : {}),
        backgroundSize: '100vw',
        backgroundRepeat: 'no-repeat',
        padding: theme.spacing(4),
        height: backgroundImage ? '41.3vw' : theme.spacing(63),
        width: '100vw',
        backgroundPosition: 'center',
        justifyContent: 'center',
        display: 'flex',
        zIndex: 0,
        '&:after': {
          display: 'none'
        },
        [theme.breakpoints.down('lg')]: {
          minHeight: theme.spacing(78),
          backgroundSize: 'cover'
        }
      }}
    >
      <Box
        width={680}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        maxWidth={'100%'}
        textAlign={'center'}
      >
        <Hidden mdDown={true}>
          <Typography variant={'h1'}>{headline}</Typography>
        </Hidden>
        <Hidden smUp={true}>
          <Typography variant={'h2'}>{headline}</Typography>
        </Hidden>
        {description && (
          <Box marginTop={4} marginBottom={5}>
            <Typography variant={'body1'}>{description}</Typography>
          </Box>
        )}
        {button && (
          <Link
            color={'inherit'}
            underline={'none'}
            href={button.href as string}
          >
            <Button {...button} href={undefined}>
              {button.children}
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
};

Hero.strapiComponent = 'maastr.hero';

export { Hero };
